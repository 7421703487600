import React from 'react'
import Navbar from '../components/Navbar'
import DealerCar from '../components/DealerCar'
export default function DealerPage() {
  return (
    <>
    
    <Navbar/>
    <DealerCar/>
    </>
  )
}
