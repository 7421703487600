import React from 'react'
import Navbar from '../components/Navbar'
import Footer from '../components/Footer'
import About from '../components/About'
import FeaturedCars from '../components/FeaturedCars'
export default function HomePage() {
  return (
<>
<div>

<Navbar/>
<About/>
<FeaturedCars/>
<Footer/>
</div>

</>
  )
}
